.autocomplete {
  --rt-select-padding: 1em;
  --rt-select-border: 1px solid #222;
  --rt-select-border-radius: 4px;
  --rt-select-background-color: #fff;
  --rt-select-background-color-active-option: #e6e6e6;
  --rt-select-focus-color: #0067b8;
  --rt-select-max-height: 300px;
  --rt-select-width-icons: 16px;
}

.autocomplete {
  position: relative;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
}

.autocomplete-label {
  // screen-reader only element by default
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.autocomplete-input-container {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: var(--rt-select-padding);
  background-color: var(--rt-select-background-color);
  border: var(--rt-select-border);
  border-radius: var(--rt-select-border-radius);
}

.autocomplete-icon {
  border: none;
  background: none;

  &:focus {
    outline: 3px solid var(--rt-select-focus-color);
  }

  &--reset {
    order: -1;
  }

  &--search {
    order: 1;
  }

  img {
    width: var(--rt-select-width-icons);
  }
}

.autocomplete-input {
  flex: 1;
  min-width: 0;

  border: none;

  &:focus {
    outline: 3px solid var(--rt-select-focus-color);
  }
}

.open .autocomplete-input-container {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autocomplete-menu {
  display: none;

  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;

  background-color: #f5f5f5;
  border: var(--rt-select-border);
  border-top: none;

  border-bottom-left-radius: var(--rt-select-border-radius);
  border-bottom-right-radius: var(--rt-select-border-radius);

  max-height: var(--rt-select-max-height);
  overflow-y: auto;
  width: 100%;
}

.open .autocomplete-menu {
  display: revert;
}

.autocomplete-option {
  padding: var(--rt-select-padding);
  background-color: var(--rt-select-background-color);

  &.option-current {
    outline: 3px solid var(--rt-select-focus-color);
    outline-offset: -3px;
  }

  &:hover {
    background-color: var(--rt-select-background-color-active-option);
  }
}
