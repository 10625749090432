// Built starting from famous Andy Bell's reset:
// https://andy-bell.co.uk/a-modern-css-reset/

// ! global page resets
:root {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

// ! storelocator-specific resets
// Note: :where() pseudo-class allows to:
// 1) avoid code repetition in the generated CSS
// 2) lower the specificity: otherwise, all the rules would be generated as
//    .rt-homepage img {...}
//    and it would be a pain to override them in the future
:where(.rt-homepage, .rt-concierge, .rt-seo-page, .rt-brand-finder) {
  * {
    scroll-behavior: smooth;
  }

  // Box sizing rules and font inheriting
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  // Remove list styles on ul and ol elements
  ul,
  ol {
    list-style: none;
  }

  // Remove text decoration from a elements, change cursor, inherit color
  a {
    text-decoration: none;
    color: inherit;

    cursor: pointer;
  }

  // Make images easier to work with
  img,
  picture {
    display: block;
    max-width: 100%;

    width: revert;
    height: revert;
  }

  // Inherit fonts for inputs, buttons, textarea, select
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  // Remove border, background, inherit color and change cursor
  // for buttons
  button {
    border: none;
    background: none;

    color: inherit;

    cursor: pointer;
  }

  // Remove all animations, transitions and smooth scroll for people that prefer not to see them
  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
}
