.rt-skip-link {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 100000000;

  translate: -50% -100%;
  transition: translate 0.3s;

  &:focus-visible {
    translate: -50% 0;
  }
}
