.rt-sidebar__content {
  --_rt-sidebar-padding: 50px 20px;
  --_rt-sidebar-max-width: 500px;
}

.rt-filters {
  display: grid;
  grid-template-rows: auto 1fr auto;

  height: 100%;

  > * {
    padding-inline: 1em;
  }

  &__title {
    display: block;

    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;

    // number of selected items

    &::after {
      content: "(" attr(data-selected-options) ")";

      display: inline-block;
      margin-left: 0.25em;
    }

    &:not([data-selected-options]),
    &[data-selected-options=""],
    &[data-selected-options="0"] {
      &::after {
        content: "";
      }
    }
  }

  // * header
  &__header {
    display: flex;
    gap: 30px;

    padding-bottom: 32px;
  }

  &__heading {
    margin-right: auto;

    font-size: 16px;
    font-weight: 400;
  }

  &__reset {
    text-decoration: underline;
  }

  &__header .rt-btn-close img {
    width: 17px;
  }

  // * body
  &__body {
    overflow: hidden auto;
  }

  &__applied-filters {
    border-block: 1px solid #e5e5e5;
    padding-block: 10px 20px;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;

      padding-top: 20px;
    }
  }

  &__applied-filter {
    display: flex;
    align-items: center;
    gap: 0.5em;

    padding: 0.35em 1em;
    border: 1px solid currentColor;
    border-radius: 4px;

    .rt-btn-close {
      flex-shrink: 0;

      img {
        width: 10px;
      }
    }
  }

  &__category ul {
    display: grid;
    gap: 1em;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 1em;

    cursor: pointer;

    > span {
      display: flex;
      align-items: center;
      gap: 0.75em;
    }

    img {
      width: 19px;
    }
  }

  // * footer
  &__footer {
    padding-top: 2em;
  }

  &__show-results {
    width: 100%;
  }
}

// brand
.rt-filters__brands-autocomplete {
  display: flex;
  align-items: center;
  gap: 1em;
  border: 1px solid #000;
  padding: 0.75em 1.25em;
  margin-block: 2em;

  input {
    flex: 1;
    border: none;

    &:focus {
      outline: none;
    }
  }
}
