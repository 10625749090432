@use "../abstracts/mixins";

.rt-header-and-controls {
  background-color: #000;
  padding-block: 2em;
  color: #999999;
  font-weight: 500;

  &,
  .rt-btn {
    @include mixins.mq(desktop, down) {
      font-size: 12px;
    }
  }

  @include mixins.mq(desktop, up) {
    padding-block: 2em;
  }

  .rt-btn {
    border: 1px solid #fff;
  }
}

.rt-header-and-controls {
  .rt-main-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;

    --rt-main-container-max-width: 1800px;

    .rt-controls {
      grid-column: 1 / -1;
    }

    @include mixins.mq(tablet, up) {
      grid-template-columns: 1fr 1fr;
    }

    @include mixins.mq(large-desktop, up) {
      grid-template-columns: 1fr auto 1fr;

      > * {
        grid-row-start: 1;
      }

      .rt-logo {
        grid-column: 1 / 2;
      }

      .rt-nav {
        grid-column: -2 / -1;
      }
    }
  }
}

.rt-logo {
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  img {
    width: 110px;

    @include mixins.mq(desktop, up) {
      width: 137px;
    }
  }

  span {
    @include mixins.mq(desktop, down) {
      font-size: 11px;
      letter-spacing: 0.05em;
    }
  }
}

.rt-nav {
  display: flex;
  justify-content: flex-end;
  align-items: start;
  gap: 3em;

  text-transform: uppercase;

  @include mixins.mq(tablet, up) {
    align-items: center;
  }
}

.rt-controls {
  display: grid;
  gap: 1em;

  @include mixins.mq(desktop, up) {
    align-self: center;

    width: 900px;
    margin-inline: auto;
    grid-template-columns: repeat(2, 1fr) auto;
  }
}
