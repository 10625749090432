@use "../abstracts/mixins";

#ppms-modal {
  font-family: var(--rt-font-family-primary);

  a {
    text-decoration: none;
  }

  #c-modal-consent-title {
    margin-block: 10px;

    font-size: 14px;
    font-weight: 500;

    @include mixins.mq(desktop, up) {
      font-size: 18px;
    }
  }

  #c-modal-consent-p1 {
    margin-block: 0 10px;

    font-size: 13px;
  }

  .ppms-cta-button {
    font-family: var(--rt-font-family-primary);
    font-size: 11.5px;
  }
}
