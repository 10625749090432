.rt-tabs {
  .rt-is-hidden {
    display: none;
  }

  [role="tablist"] {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
  }
}
