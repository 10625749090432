@use "../abstracts/mixins";

.rt-store-card {
  --_rt-store-card-padding: 0;
  --_rt-store-card-border: none;
  --_rt-store-card-border-radius: 0;
  --_rt-store-card-background-color: transparent;
  --_rt-store-card-info-gap: 0.15em;

  --_rt-store-card-cta-min-width: 175px;
}

.rt-store-card {
  position: relative;

  background-color: var(--_rt-store-card-background-color);
  border: var(--_rt-store-card-border);
  padding: var(--_rt-store-card-padding);

  border-radius: var(--_rt-store-card-border-radius);

  .rt-next-opening {
    align-self: flex-start;

    margin-bottom: 1.5em;
  }

  &__heading {
    margin-bottom: 0.5em;
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 0.5em;

    font-weight: 500;
  }

  &__info {
    font-weight: 400;

    > * {
      display: block;
    }

    > * + *:not(:has(.rt-accordion)) {
      margin-top: var(--_rt-store-card-info-gap);
    }

    & {
      margin-bottom: 24px;
    }
  }

  &__contacts {
    > * + * {
      margin-top: var(--_rt-store-card-info-gap);
    }

    img {
      width: 15px;
      height: 15px;
    }

    a {
      display: inline-flex;
      align-items: center;
      gap: 0.5em;

      overflow-wrap: anywhere;
    }
  }

  .rt-store-card__hours-today {
    display: inline-flex;
    gap: 0.35em;
    font-weight: 500;
  }

  &__cta {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

    > * {
      flex: 1;
      min-width: var(--_rt-store-card-cta-min-width);
    }
  }

  // * For tablet views, things could get akward
  // * Since cards lay out on a 2-col grid
  // * So, we want to make sure that they are always the same height,
  // * And that the ctas are stuck to the very bottom of the card
  & {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__cta {
    margin-top: auto;
  }
}
