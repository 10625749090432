@use "../abstracts/mixins";

.rt-list-container {
  // * store count
  --_rt-store-count-text-alignement: start; // [start, center, end]
  --_rt-store-count-font-size: inherit;
  --_rt-store-count-color: inherit;
  --_rt-store-count-margin-bottom: 24px;

  // * store list
  --_rt-store-list-row-gap: 4em;
  --_rt-store-list-column-gap: 3em;
  --_rt-store-card-min-width: 375px;
}

// * list container
.rt-list-container {
  // We need this overflow-y: auto in order to make this
  // list container scrollable
  overflow-y: auto;

  background-color: #fff;
}

// * store count
.rt-store-count {
  display: block;

  margin-bottom: var(--_rt-store-count-margin-bottom);

  font-size: var(--_rt-store-count-font-size);
  text-align: var(--_rt-store-count-text-alignement);
  color: var(--_rt-store-count-color);
}

// * store list
.rt-store-list {
  display: grid;
  gap: var(--_rt-store-list-row-gap) var(--_rt-store-list-column-gap);

  @include mixins.mq(tablet, up) {
    grid-template-columns: 1fr 1fr;
  }

  @include mixins.mq(desktop, up) {
    grid-template-columns: 1fr;
    max-width: 550px;
    margin-inline: auto;
  }
}

// * back to top
.rt-back-to-top {
  @include mixins.mq(desktop, down) {
    display: none;
  }
}
