@use "../abstracts/mixins";

.rt-homepage {
  .autocomplete {
    --rt-select-focus-color: #000;
    --rt-select-border-radius: 0;
    --rt-select-border: 1px solid #d8dadf;
    --rt-select-padding: 0.6em 1.5em;
    --rt-select-background-color-active-option: #f6f5f4;

    font-size: 14px;
    font-weight: 400;
    color: #000;

    .autocomplete-input:focus {
      outline: none;
    }

    .autocomplete-option.option-current {
      outline: none;
      background-color: var(--rt-select-background-color-active-option);
    }

    * {
      @include mixins.scrollbar(
        $clr-scrollbar-thumb: transparent,
        $clr-scrollbar-track: transparent,
        $scrollbar-width: none,
        $border-radius: 0
      );
    }

    .autocomplete-menu {
      z-index: 99999999;
    }

    &,
    .autocomplete-input-container {
      height: 100%;
    }

    .autocomplete-input-container {
      padding: var(--rt-select-padding);
    }
  }
}
