@use "../abstracts/mixins";

.rt-modal {
  display: grid;
  place-items: center;

  position: fixed;
  inset: 0;

  background-color: rgba(0, 0, 0, 0.427);

  color: inherit;

  border-radius: var(--overlay-border-radius, 0);

  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 500ms;

  &--visible {
    opacity: 1;
    visibility: visible;
  }

  @include mixins.mq(desktop, up) {
    padding: 3em;
  }
}

// * position-consent modal

.rt-modal__content {
  z-index: 10;

  position: relative;

  width: min(90%, 650px);
  background-color: #fff;
  padding: 2.5em;
  text-align: center;

  .rt-btn-close {
    position: absolute;
    top: 1em;
    right: 1em;

    img {
      width: 17px;
    }
  }
}

.rt-modal__title {
  margin-bottom: 1.5em;

  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}

.rt-modal__message {
  margin-bottom: 2em;
}

.rt-modal__actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  max-width: 350px;
  margin: auto;
}

.rt-modal__actions > * {
  flex: 1 1 150px;
}
