// prettier-ignore
:where(body) {.rt-back-to-top{position:fixed;right:20px;bottom:20px;z-index:100;padding:20px;background-color:rgba(0,0,0,.8);background-repeat:no-repeat;background-position:center;background-size:20px;box-shadow:0 0 10px rgba(0,0,0,.7);border-radius:100vmax;visibility:hidden;opacity:0;transition:opacity 500ms ease-out;cursor:pointer}.rt-back-to-top--visible{visibility:visible;opacity:1}}

.rt-back-to-top {
  --_rt-back-to-top-background-color: var(--rt-color-brand);
  --_rt-back-to-top-chevron-color: white;
}

.rt-back-to-top.rt-back-to-top.rt-back-to-top {
  position: sticky;
  float: right;
  z-index: 100000;
  bottom: 0;
  right: 0;
  translate: -20px -30px;

  background-color: var(--_rt-back-to-top-background-color);
  box-shadow: none;

  backdrop-filter: blur(2px);

  // * chevron
  &::after {
    content: "";

    position: absolute;
    inset: 0;
    margin: auto;
    width: 20px;
    aspect-ratio: 1;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='9' viewBox='0 0 15 9' fill='none'%3E%3Cpath d='M1.52369 7.70608L7.52369 1.70608L13.5237 7.70608' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}
