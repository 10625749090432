@use "../abstracts/mixins";

.rt-powered-by {
  --_rt-powered-by-gap: 1em;
  --_rt-powered-by-padding-block: 24px;
  --_rt-powered-by-font-size: 12px;
  --_rt-powered-by-background-color: revert;
  --_rt-powered-by-color: inherit;
}

.rt-powered-by {
  // powered-by footer is a flex-item, living in a flex container
  // which is the page container (".rt-homepage")
  // so we take advantage of that, setting the margin-top of this element to auto
  // so that we always obtain a so called "sticky footer"
  margin-top: auto;

  background-color: var(--_rt-powered-by-background-color);

  font-size: var(--_rt-powered-by-font-size);
  color: var(--_rt-powered-by-color);

  .rt-main-container {
    display: flex;
    align-items: center;
    gap: var(--_rt-powered-by-gap);

    padding-block: var(--_rt-powered-by-padding-block);

    text-align: center;

    // We always want the "RetailTune" logo
    // to be alone on the right end of the footer
    // so, we take advantage of the flex container
    // and we set a margin-left: auto on this element
    // which *MUST* always be the last child of its container
    .rt-powered-by__link:last-child {
      margin-left: auto;
    }
  }

  .rt-powered-by__link:not(:has(.rt-logo-retail)) {
    text-decoration: underline;
  }

  .rt-logo-retail,
  .rt-logo-tune {
    font-family: Arial, Helvetica, sans-serif;
  }

  .rt-logo-retail {
    color: #1d1d1b;
    font-weight: 700;
  }

  .rt-logo-tune {
    color: #f0ad4e;
    font-weight: 400;
  }
}
