@use "../abstracts/mixins";

.rt-map-list-container {
  // flex: 1 is needed so that this area can "stretch" to fill
  // the remaining vertical space on the page
  // (i.e.: 100vh - header's height - controls' height)
  flex: 1;

  // overflow: hidden is needed both on the x and on the y axis:
  // - x because of the directions pane
  // - y because of the list container
  overflow: hidden;

  display: grid;
  padding-inline: 0 !important;

  // Here we set the grid template for desktop-up viewports
  // It will always be a 2-col layout, we just switch the columns
  // based on the fact that we have a class "--list-right" or "--list-left"
  @include mixins.mq(desktop, up) {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    &--list-right {
      grid-template-areas: "map map list";
      margin-right: 5px;
    }

    &--list-left {
      grid-template-areas: "list map map";
    }

    .rt-map-container {
      grid-area: map;
    }
  }
}

.rt-map-container {
  background-color: #e6e6e6;
  min-height: 450px;

  @include mixins.mq(desktop, down) {
    min-height: 450px;
  }
}

// * back to nearest store fuctionality
.rt-map-container {
  position: relative;
  overflow: hidden;
}

.rt-map {
  width: 100%;
  height: 100%;
}

.rt-back-to-nearest-store.rt-back-to-nearest-store {
  visibility: hidden;
  opacity: 0;
  transition-property: visibility, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-transform: uppercase;

  box-shadow: 0 0 0 100vmax rgba(158, 158, 158, 0.6);

  &--visible {
    visibility: visible;
    opacity: 1;
  }
}

// We want both the list and the directions pane
// to occupy the very same area in the grid layout
:is(.rt-list-container, .rt-directions-pane) {
  padding-block: 3em 1em;
  padding-inline: var(--rt-padding-inline-homepage);

  @include mixins.mq(desktop, up) {
    grid-area: list;
    padding-block: 3em;
    padding-inline: 3em;
  }
}
