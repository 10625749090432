@use "../abstracts/mixins";

.rt-btn {
  --_rt-btn-padding: 0.6em 1.5em;
  --_rt-btn-border-radius: var(--rt-border-radius-buttons);
  --_rt-btn-font-size: 15px;
  --_rt-btn-font-weight: 500;

  // primary
  --_rt-btn-primary-background-color: var(--rt-color-brand);
  --_rt-btn-primary-border: none;
  --_rt-btn-primary-color: #fff;

  // secondary
  --_rt-btn-secondary-background-color: white;
  --_rt-btn-secondary-border: 1px solid currentColor;
  --_rt-btn-secondary-color: var(--rt-color-text);

  // tertiary
  --_rt-btn-tertiary-background-color: #f7f7f8;
  --_rt-btn-tertiary-border: none;
  --_rt-btn-tertiary-color: var(--rt-color-text);
}

.rt-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1em;

  padding: var(--_rt-btn-padding);

  font-size: var(--_rt-btn-font-size);
  font-weight: var(--_rt-btn-font-weight);
  text-align: center;
  text-transform: uppercase;

  border-radius: var(--_rt-btn-border-radius);

  // hover state
  transition-property: opacity;
  transition-duration: 300ms;

  &--primary {
    background-color: var(--_rt-btn-primary-background-color);
    color: var(--_rt-btn-primary-color);
    border: var(--_rt-btn-primary-border);
  }

  &--secondary {
    background-color: var(--_rt-btn-secondary-background-color);
    color: var(--_rt-btn-secondary-color);
    border: var(--_rt-btn-secondary-border);
  }

  &--tertiary {
    background-color: var(--_rt-btn-tertiary-background-color);
    color: var(--_rt-btn-tertiary-color);
    border: var(--_rt-btn-tertiary-border);
  }

  &:hover {
    opacity: 0.7;
  }
}

.rt-btn-geo,
.rt-btn-filters {
  justify-content: space-between;

  img {
    width: 15px;
  }
}
