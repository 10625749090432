@use "../abstracts/mixins";

.rt-directions-pane {
  --_rt-directions-pane-background-color: #fff;
  --_rt-directions-pane-padding-block: 0;

  // * close icon
  --_rt-directions-pane-close-icon-size: 16px;
  --_rt-directions-pane-close-icon-offset-top: 20px;
  --_rt-directions-pane-close-icon-offset-right: 20px;

  // * travel modes
  --_rt-directions-pane-travel-modes-gap: 32px;

  // * addresses area
  --_rt-directions-pane-addresses-padding: 0.5em 1em;
  --_rt-directions-pane-addresses-border: 1px solid #cccccc;
  --_rt-directions-pane-addresses-background-color: white;
  --_rt-directions-pane-addresses-border-radius: 0;
  --_rt-directions-pane-addresses-icon-width: 25px;
  --_rt-directions-pane-addresses-icon-gap: 16px;
  --_rt-directions-pane-addresses-font-size: 14px;

  // * instructions area
  --_rt-directions-pane-instructions-label-icon-width: 28px;
}

// * transition
// We use :where() to help lowering the specificity
// since we need to re-assign the transform property when the
// "--visible" class is applied later in the code
:where(.rt-map-list-container--list-right .rt-directions-pane) {
  transform: translateX(101%);
}

// If we chose a "--list-left" container, the directions pane needs to
// slide from left to right
:where(.rt-map-list-container--list-left .rt-directions-pane) {
  transform: translateX(-101%);
}

.rt-directions-pane {
  position: relative; // necessary for the close icon

  background-color: var(--_rt-directions-pane-background-color);
  overflow-y: auto;

  visibility: hidden;

  transition-property: transform, visibility;
  transition-duration: 300ms;

  &--visible {
    transform: translateX(0);
    visibility: visible;
  }

  @include mixins.mq(desktop, down) {
    display: none;
  }
}

// * close icon
.rt-directions-pane .rt-btn-close {
  position: absolute;
  top: var(--_rt-directions-pane-close-icon-offset-top);
  right: var(--_rt-directions-pane-close-icon-offset-right);

  img {
    width: var(--_rt-directions-pane-close-icon-size);
    height: var(--_rt-directions-pane-close-icon-size);

    object-fit: contain;
  }
}

// * travel modes
.rt-travel-modes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--_rt-directions-pane-travel-modes-gap);

  margin-bottom: 2em;
}

.rt-travel-mode__icon {
  width: 35px;
  max-height: 35px;
}

// show the correct icon
.rt-travel-mode__icon:last-child {
  display: none;
}

.rt-travel-mode--selected {
  .rt-travel-mode__icon {
    &:first-child {
      display: none;
    }
    &:last-child {
      display: block;
    }
  }
}

// * addresses area
.rt-addresses-area {
  display: grid;
  gap: 16px;

  margin-bottom: 40px;
}

.rt-address-origin .autocomplete {
  --rt-select-padding: var(--_rt-directions-pane-addresses-padding);
  --rt-select-border: var(--_rt-directions-pane-addresses-border);
  --rt-select-background-color: var(--_rt-directions-pane-addresses-background-color);
  --rt-select-border-radius: var(--_rt-directions-pane-addresses-border-radius);
  --rt-select-width-icons: var(--_rt-directions-pane-addresses-icon-width);
  --rt-select-gap-icons: var(--_rt-directions-pane-addresses-icon-gap);
  --rt-select-font-size: var(--_rt-directions-pane-addresses-font-size);
}

.rt-address-origin .autocomplete-input-container::before {
  order: -100;
  content: "";
  width: var(--_rt-directions-pane-addresses-icon-width);
  height: var(--_rt-directions-pane-addresses-icon-width);
  background-image: url("/img/pin/pin-user.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.rt-address-destination {
  display: flex;
  align-items: center;
  gap: var(--_rt-directions-pane-addresses-icon-gap);

  padding: var(--_rt-directions-pane-addresses-padding);
  background-color: var(--_rt-directions-pane-addresses-background-color);
  border: var(--_rt-directions-pane-addresses-border);

  font-size: var(--_rt-directions-pane-addresses-font-size);

  border-radius: var(--_rt-directions-pane-addresses-border-radius);

  img {
    width: var(--_rt-directions-pane-addresses-icon-width);
    height: var(--_rt-directions-pane-addresses-icon-width);
  }
}

.rt-address-origin .rt-input-container::before {
  content: "";
  min-width: var(--_rt-autocomplete-width-icons);
  height: 26px;
  background-image: url("/img/pin/pin-user.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

// * total time and distance area
.rt-total-travel-distance-and-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;

  padding: 1em;
  background-color: #efefef;
}

// * instructions area

.rt-instructions-label {
  display: flex;
  align-items: center;
  gap: 0.75em;

  font-size: 14px;
  font-weight: 500;

  img {
    width: var(--_rt-directions-pane-instructions-label-icon-width);
  }
}

.rt-instructions-label--origin {
  margin-bottom: 20px;
}

.rt-instructions-list {
  margin-bottom: 20px;
}

.rt-instructions-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }

  > div {
    display: flex;

    align-items: flex-start;
    gap: 1em;

    font-weight: 400;

    strong {
      font-weight: 500;
    }
  }
}
