.rt-accordion {
  --rt-accordion-border-bottom: 1px solid #efefef;
  --rt-accordion-trigger-padding: 1em 0;
  --rt-accordion-panel-padding: 0;
  --rt-accordion-width-icon: 10px;
  --rt-accordion-border-radius: 0;
  --rt-accordion-focus-color: currentColor;
}

.rt-accordion {
  // borders
  border-bottom: var(--rt-accordion-border-bottom);

  .rt-accordion-trigger {
    border-bottom: none;
  }

  // padding
  .rt-accordion-panel {
    padding-bottom: 1em;
  }

  // focus

  &:focus-within,
  .rt-accordion-trigger:focus .rt-accordion-title {
    outline: none;
  }

  &:has(input:focus) {
    outline: none;
  }

  &:has(:focus-visible) {
    outline: 3px solid var(--rt-accordion-focus-color);
  }
}
