@use "../abstracts/mixins";

:where(.rt-homepage, .rt-concierge, .rt-seo-page, .rt-brand-finder) {
  // Of course, we always want our page to be at least 100vh tall
  min-height: 100vh;

  // * Basic typography rules
  // These rules are defined here in order to be inherited by default
  // by each and every children
  font-family: var(--rt-font-family-primary, sans-serif);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--rt-color-text, black);

  accent-color: var(--rt-color-brand, black);

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  * {
    @include mixins.scrollbar(
      $clr-scrollbar-thumb: var(--rt-color-brand, black),
      $clr-scrollbar-track: #f7f7f8,
      $scrollbar-width: 6px,
      $border-radius: 0
    );
  }
}
