@use "../abstracts/mixins";

.rt-brands {
  &__search-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;

    margin-bottom: 2em;
    padding: 0.75em 1.25em;
    background-color: #fff;
    border: 1px solid #000;

    &:focus-within {
      outline: 1px solid #000;
    }

    @include mixins.mq(desktop, up) {
      max-width: 400px;
    }

    input {
      width: 100%;
      border: none;
      background: none;

      &:focus {
        outline: none;
      }
    }
  }

  // tabs
  .rt-tablist {
    margin-bottom: 3em;

    > button {
      padding: 1em;
      line-height: 1;

      &[aria-selected="true"] {
        background-color: var(--rt-color-brand);
        color: #fff;
      }

      &:not([role="tab"]) {
        opacity: 0.2;
      }
    }
  }

  .rt-tabpanels {
    &__letter {
      display: inline-block;

      margin-bottom: 2em;
      background-color: var(--rt-color-brand);
      color: #fff;
      padding: 1em;

      line-height: 1;
    }

    &__results {
      display: grid;
      gap: 1em;
    }
  }

  .rt-btn-brands-show-more {
    margin-top: 1em;
  }
}

.rt-concierge .rt-brands {
  margin-block: 48px;

  @include mixins.mq(desktop, up) {
    margin-block: 60px;
  }

  &__heading {
    margin-bottom: 2em;

    text-align: center;
    font-size: 20px;
    font-weight: 500;

    @include mixins.mq(desktop, up) {
      font-size: 26px;
    }
  }

  &__search-area {
    padding: 32px 24px;
    background-color: #f7f7f8;

    @include mixins.mq(desktop, up) {
      padding: 40px;
    }
  }

  .rt-tabpanels {
    &__results {
      grid-template-columns: repeat(2, 1fr);

      @include mixins.mq(tablet, up) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include mixins.mq(desktop, up) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
