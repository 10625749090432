@use "../abstracts/mixins";

// All the pages have a maximum width, to avoid problems
// on large screens
.rt-homepage,
.rt-concierge,
.rt-seo-page {
  max-width: 2500px;
  margin-inline: auto;
}

// This utility class helps defining a generic container
// defined through a padding-inline, specificed by user both
// for mobile and desktop viewports
// Having a class like this one helps mantaining coherence through
// the various components
.rt-main-container {
  --rt-main-container-max-width: 1440px;
  --rt-main-container-relative-width: 90%;

  width: min(var(--rt-main-container-max-width), var(--rt-main-container-relative-width));
  margin-inline: auto;
}

.rt-homepage {
  --rt-padding-inline-homepage: 20px;

  @include mixins.mq(desktop, up) {
    --rt-padding-inline-homepage: 60px;
  }

  .rt-main-container {
    width: revert;
    margin-inline: revert;
    padding-inline: var(--rt-padding-inline-homepage);
  }
}
