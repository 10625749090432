.rt-accordion {
  --rt-accordion-trigger-padding: 1em;
  --rt-accordion-panel-padding: 1em;
  --rt-accordion-border-bottom: 1px solid #222;
  --rt-accordion-width-icon: 16px;
  --rt-accordion-focus-color: #0067b8;
  --rt-accordion-border-radius: 4px;
}

.rt-accordion {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  & {
    border-radius: var(--rt-accordion-border-radius);
  }

  &:focus-within {
    outline: 3px solid var(--rt-accordion-focus-color);
    outline-offset: 0px;
  }

  .rt-accordion-trigger {
    width: 100%;
    background-color: transparent;
    border: none;
    padding: var(--rt-accordion-trigger-padding);
    border-bottom: var(--rt-accordion-border-bottom);

    text-align: start;

    &:focus {
      outline: none;
    }
  }

  .rt-accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;

    border-radius: var(--rt-accordion-border-radius);
  }

  .rt-accordion-trigger:focus .rt-accordion-title {
    outline: 3px solid var(--rt-accordion-focus-color);
    outline-offset: 0px;
  }

  .rt-accordion-icon {
    width: var(--rt-accordion-width-icon);
  }

  .rt-accordion-trigger[aria-expanded="true"] .rt-accordion-icon {
    rotate: 0.5turn;
  }

  .rt-accordion-panel {
    padding: var(--rt-accordion-panel-padding);
  }
}
